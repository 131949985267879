import React from 'react';
import { ProgramData } from '../../../lib/interfaces';
import { stepConfig as QualifyingStepConfig } from './stepper/steps/QualifyingStep';
import { stepConfig as ExpenseClassificationStepConfig } from './stepper/steps/ExpenseClassificationStep';
import { stepConfig as ExpertReviewStepConfig } from './stepper/steps/ExpertReviewStep';
import { stepConfig as ClientReviewStepConfig } from './stepper/steps/ClientReviewStep';
import { stepConfig as FinishedStepConfig } from './stepper/steps/FinishedStep';

export interface StepProps {
  federalRDProgram: ProgramData;
  programs?: ProgramData[];
}

export interface Step {
  name: string;
  number: number;
  title: string;
  dataTestId: string;
  faq: React.ReactElement;
}

interface StepsList {
  [key: string]: Step;
}

export const TaxCreditProgramSteps: StepsList = {
  qualifying: QualifyingStepConfig,
  expense_classification: ExpenseClassificationStepConfig,
  ms_review: ExpertReviewStepConfig,
  client_review: ClientReviewStepConfig,
  finished: FinishedStepConfig,
};
