import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Color,
  Content,
  Text,
  Chip,
  Icon,
  IconEnum,
  Flex,
} from 'component-library';
import { makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import StagingBanner from 'components/util/StagingBanner';
import ProductionBanner from 'components/util/ProductionBanner';
import { useCommonStores, useCompany, useFeatureFlags } from 'stores/useStores';
import { observer } from 'mobx-react';
import classnames from 'classnames/bind';
import { datadogLogs } from '@datadog/browser-logs';

const useStyles = makeStyles(({ breakpoints, dividerBorderLight }: Theme) => ({
  rightLinks: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      borderLeft: 'none',
    },
    zIndex: 1,
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: dividerBorderLight,
  },
  rootSmall: {
    width: 'calc(100% - 32px)',
    marginLeft: '16px',
  },
  listLink: {
    color: Color.neutral._90,
    backgroundColor: Color.neutral._light_20,
    padding: '16px',
    fontSize: '15px',
    fontWeight: 500,
  },
  menuOpenButton: {
    cursor: 'pointer',
    border: '0',
    backgroundColor: 'Transparent',
    backgroundRepeat: 'no-repeat',
    '&:focus': {
      outline: 'none',
    },
  },
  companyInfo: {
    textAlign: 'right',
    maxWidth: '200px',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    color: 'rgb(115, 128, 137)',
    fontSize: '32px',
  },
  popper: {
    marginTop: '10px',
    backgroundColor: Color.neutral.white,
    minWidth: '250px',
    borderRadius: 4,
    boxShadow: '0px 0px 11px rgba(12, 13, 13, 0.1)',
    zIndex: 10000,
  },
  footer: {
    margin: '4px',
    width: 'inherit',
  },
  logoutButton: {
    width: '100%',
  },
}));

export const NavBar = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { company } = useCompany();
  const { auth, app } = useCommonStores();
  const featureFlags = useFeatureFlags();

  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const renderedName =
    auth.user?.displayName ||
    `${company.adminName.first} ${company.adminName.last}`;

  interface MenuLink {
    name: string;
    action?: () => void;
  }

  const onOrgChange = (orgId: string) => () => {
    datadogLogs.logger.info(`
      ${renderedName} switched company from ${company.auth0OrganizationId} to ${orgId}.
    `);
    auth.setOrganizationId(orgId);
    window.location.assign('/');
  };
  const userOrgs = auth
    .getUserOrgs()
    .filter((userOrg) => userOrg.id !== company.auth0OrganizationId)
    .map((userOrg) => {
      return {
        name: userOrg.name,
        action: onOrgChange(userOrg.id),
      };
    });
  const MENU_LINKS: Array<MenuLink> = userOrgs.length
    ? userOrgs
    : [{ name: company.name }];

  const toggleMenu = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setSideMenuOpen(open);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSideMenuOpen(false);
    }
  }

  if (!app.showTopNav) {
    return <></>;
  }

  return (
    <div data-testid='mst-navbar'>
      {featureFlags.showProductionBanner && <ProductionBanner />}
      <StagingBanner />
      {company.isLoaded && (
        <Flex padding={[0, 40]}>
          <Content
            className={`${classes.root} ${
              app.isSmallDesktop && classes.rootSmall
            }`}
          >
            <div className={classes.rightLinks} ref={anchorRef}>
              <div className={classes.companyInfo}>
                <Text
                  dataTestId='user-name'
                  text={renderedName}
                  variant='medium'
                />
                <Text text={company.name} size={15} />
              </div>
              <button
                tabIndex={0}
                data-testid='nav-button'
                className={classes.menuOpenButton}
                onClick={toggleMenu(true)}
              >
                <KeyboardArrowDownIcon className={classes.icon} />
              </button>
            </div>
            <Popper
              open={sideMenuOpen}
              placement={'bottom-end'}
              anchorEl={anchorRef.current}
              transition
              className={classes.popper}
            >
              <ClickAwayListener onClickAway={toggleMenu(false)}>
                <MenuList
                  disablePadding
                  autoFocusItem={sideMenuOpen}
                  onKeyDown={handleListKeyDown}
                >
                  <Flex padding={[24, 16]} gap={16}>
                    <Flex.Cell grow={2}>
                      <Text text={company.name} variant='bold' size={18} />
                    </Flex.Cell>
                    <Flex.Cell alignSelf='center'>
                      <Chip
                        showIcon={false}
                        text='Active'
                        textColor={Color.green._60}
                        textSize={13}
                        variant={'hard_success'}
                      />
                    </Flex.Cell>
                  </Flex>
                  {MENU_LINKS.map((menuItem) => (
                    <div key={menuItem.name}>
                      {
                        <MenuItem
                          className={classnames(
                            classes.listLink,
                            !menuItem.action,
                          )}
                          divider
                          disabled={!menuItem.action}
                          onClick={(e: any) => {
                            toggleMenu(false)(e);
                            if (menuItem.action) {
                              menuItem.action();
                            }
                          }}
                        >
                          {menuItem.name}
                        </MenuItem>
                      }
                    </div>
                  ))}
                  <div className={classes.footer}>
                    <Button
                      dataTestId={'logout-button'}
                      label={
                        <>
                          <Icon
                            name={IconEnum.logout}
                            color={Color.semantic.$error50}
                          />
                          Logout
                        </>
                      }
                      type='error'
                      className={classes.logoutButton}
                      variant='tertiary'
                      onClick={async () => await auth.fullLogout()}
                    />
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Popper>
          </Content>
        </Flex>
      )}
    </div>
  );
});
