import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Card, Color, Flex, Link, Text } from 'component-library';
import { useFeatureFlags, useTaxCreditsStores } from 'stores/useStores';
import { Page } from 'lib/constants';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
    '& > div': {
      borderTop: `1px solid ${Color.neutral._20}`,
    },
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  list: {
    margin: 0,
    padding: '0 24px',
  },
}));

interface TaxCreditPageBodyProps {
  bodyTitle: string;
  bodySubtitle: string | JSX.Element;
}

export const TaxCreditPageBody = observer(
  ({ bodyTitle, bodySubtitle }: TaxCreditPageBodyProps) => {
    const classes = useStyles();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const showERCCredit =
      featureFlags.showERCCreditInEstimates &&
      unifiedTaxCredits.hasQualifyingERCProgram();

    return (
      <>
        <Card noMargin>
          <Flex direction='column' gap={16} padding={24}>
            <Flex
              justifyContent='space-between'
              alignItems='center'
              wrap='wrap'
            >
              <Flex
                gap={4}
                alignItems='center'
                justifyContent='flex-start'
                className={classes.title}
                basis={315}
              >
                <Text variant='medium' size={23}>
                  {bodyTitle}
                </Text>
              </Flex>
            </Flex>
            <Text paddingBottom={16}>{bodySubtitle}</Text>
            <div className={classes.assessmentContainer}>
              {showERCCredit && (
                <Flex direction='column' gap={8} padding={24}>
                  <Text variant='medium' size={18}>
                    Employee Retention Credits: Coming soon!
                  </Text>
                  <Text>
                    <Link href={`/${Page.employeeRetentionCredits}`}>
                      Click here
                    </Link>{' '}
                    to learn more.
                  </Text>
                </Flex>
              )}
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Verify payroll&nbsp;connection
                </Text>
                <Text>
                  Visit the{' '}
                  <Link href={`/${Page.connections}`}>Connections</Link> page to
                  confirm an active payroll connection. We review this data to
                  determine your eligibility for future&nbsp;credits.
                </Text>
              </Flex>
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Offer a qualifying retirement&nbsp;plan
                </Text>
                <Text>
                  You could start a 401(k) for your employees, and get up to
                  100% of the costs back as a tax&nbsp;credit!
                </Text>
              </Flex>
              <Flex direction='column' gap={8} padding={24}>
                <Text variant='medium' size={18}>
                  Invest in disabled access
                </Text>
                <Text>
                  You could receive up to $5,000 in credit each year for
                  expanding accessibility to persons with disabilities. Consider
                  spending on ergonomic office furniture, web accessibility
                  testing, and text-to-speech capabilities!
                </Text>
              </Flex>
            </div>
          </Flex>
        </Card>
      </>
    );
  },
);
