import { makeStyles } from '@material-ui/core';
import { Button, Card, Color, Flex, Text } from 'component-library';
import { Loading } from 'components/util/Loading';
import {
  DOCUMENTS,
  ExpectedCreditTypeEnum,
  PayrollTierEnum,
  ProgramNameEnum,
  Programs,
  ProgramSubStageEnum,
} from 'lib/constants';
import { TitleCase } from 'lib/helpers';
import { ProgramData } from 'lib/interfaces';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  useFeatureFlags,
  useTaxCreditsStores,
} from '../../../../../stores/useStores';
import { Tier1OrTier2Form8974SideDrawer } from '../../../fed-rd-program/8974/Tier1OrTier2Form8974SideDrawer';
import { FinishedStepFAQ } from '../../sideDrawer/FinishedStepFAQ';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { FinishedStepPayrollTax } from './FinishedStepPayrollTax';
import { FinishedStepIncomeTax } from './FinishedStepIncomeTax';

export const stepConfig: Step = {
  name: 'finished',
  number: 4,
  title: 'Apply your R&D credits',
  dataTestId: 'finished',
  faq: <FinishedStepFAQ />,
};

const useStyles = makeStyles(() => ({
  directionsCard: {
    margin: '16px 0',
  },
}));

interface FinishedStepProps extends StepProps {
  programs: ProgramData[];
}

export const FinishedStep = observer(
  ({
    federalRDProgram,
    complete,
    setComplete,
    programs,
  }: FinishedStepProps & {
    complete: boolean;
    setComplete: (val: boolean) => void;
  }) => {
    const classes = useStyles();
    const { form8974 } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();

    const [showFilingInstructions, setShowFilingInstructions] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Currently, the Finished stage is the only one that doesn't map 1-1 with a customer step.
    // If the program is finished, the customer could either be in the "setting up for redemptions"
    // phase or the "redeeming" phase.
    // The "redeeming" phases is handled in the stepper's Complete state, so we should only
    // set this step to active if the customer program is in the Finished stage, but
    // the stepper is not complete.
    const isIncomeTaxType = [
      ExpectedCreditTypeEnum.INCOME_TAX,
      ExpectedCreditTypeEnum.DEFERRED_INCOME,
    ].includes(federalRDProgram.filingCreditType);

    const programNames: ProgramNameEnum[] = programs.map(
      (program) => program.name,
    );
    const nonGaPrograms: ProgramNameEnum[] = programNames.filter(
      (p) => p !== ProgramNameEnum.STATE_RD_GA,
    );
    const nonGaOrFedPrograms: ProgramNameEnum[] = nonGaPrograms.filter(
      (p) => p !== ProgramNameEnum.FED_RD_TAX,
    );
    const redemptions: object[] = [];

    const handleCompleteShowBalanceCard = async () => {
      setIsLoading(true);
      await form8974.updateProgramSubStage(
        federalRDProgram.id,
        ProgramSubStageEnum.REDEEMING,
      );
      await form8974.getRDCreditSummary();
      setComplete(true);
      setIsLoading(false);
    };

    const readyToRedeemInstructions = () => {
      if (isLoading) {
        return <Loading loading={true} />;
      }

      return isIncomeTaxType ? (
        <FinishedStepIncomeTax onComplete={handleCompleteShowBalanceCard} />
      ) : (
        <FinishedStepPayrollTax onComplete={handleCompleteShowBalanceCard} />
      );
    };

    // add instructions for federal program
    if (!isIncomeTaxType && programNames.includes(ProgramNameEnum.FED_RD_TAX)) {
      redemptions.push(
        <li key={0}>
          <Text text='Federal R&D' variant='medium' />
          <Text
            text={`One quarter after filing your income taxes, you'll complete one more step in order to reduce your payroll taxes. Click 'Continue' below to receive more details when it's time.`}
          />
          <br />
        </li>,
      );
    }

    // payroll tax type has separate instructions for federal, so omit it here
    const multiStateList = isIncomeTaxType ? nonGaPrograms : nonGaOrFedPrograms;
    if (multiStateList.length > 0) {
      const locationString = multiStateList
        .map((p: ProgramNameEnum) => TitleCase(Programs[p].geo))
        .join(', ')
        // replace last comma with ampersand
        .replace(/,\s([^,]+)$/, ' & $1');
      redemptions.push(
        <li key={1}>
          <Text text={`${locationString} R&D`} variant='medium' />
          <Text
            text={`You're all set! Your credits will be automatically applied.`}
          />
          <br />
        </li>,
      );
    }

    // add instructions for GA program
    if (programNames.includes(ProgramNameEnum.STATE_RD_GA)) {
      redemptions.push(
        <li key={2}>
          <Text text='Georgia R&D' variant='medium' />
          <Text
            text={`File Form IT-WH within 30 days of filing state income tax returns, to lower your Georgia payroll tax liability. Otherwise, the credit will be applied to your Georgia income tax liability.`}
          />
          <br />
        </li>,
      );
    }

    return (
      <Flex
        padding={24}
        direction='column'
        style={{ backgroundColor: Color.neutral.white }}
      >
        {showFilingInstructions ? (
          <Flex direction='column' gap={24}>
            <Flex direction='column' gap={12}>
              <Text variant='medium'>
                Congrats on filing your income taxes! See below for details on
                how to use your credits to reduce your&nbsp;taxes.
              </Text>
              <Text>Make sure to follow these instructions:</Text>
              <Card
                noBorder={false}
                className={classes.directionsCard}
                noMargin
              >
                <ul>{redemptions}</ul>
              </Card>
            </Flex>
            <Button
              flexAlignSelf='flex-start'
              label={
                form8974.payrollTier === PayrollTierEnum.INCOME_TIER
                  ? 'Close'
                  : 'Continue'
              }
              dataTestId={'setup-credits-btn'}
              loading={isLoading}
              onClick={() => {
                // Check for CreditBalanceCard feature flag, open side drawer if still disabled
                if (featureFlags.isTaxCreditsPageCreditBalanceCardEnabled) {
                  if (form8974.isNotTimeForTier2Or3ToGenerate8974) {
                    /*
                      If it's not time for 8974 (not the right quarter yet), we
                      hide the filing instructions which shows the "we'll let
                      you know when it's time to file" message.
                      */
                    setShowFilingInstructions(false);
                  } else {
                    /*
                      If it's time for 8974, we continue straight to the
                      credit balance card, we also do this for Tier 1 since
                      there is no waiting period for them.
                      */
                    handleCompleteShowBalanceCard();
                  }
                } else if (form8974.payrollTier === PayrollTierEnum.TIER_1) {
                  /*
                    	Remove this when deprecating the 
                      tax-credits--tax-credits-page-creditbalancecard feature flag
                    */
                  form8974.toggleSideDrawer(true);
                } else {
                  window.location.href = `/${DOCUMENTS}`;
                }
              }}
              small
            />

            {/* Remove this when deprecating the 
		              tax-credits--tax-credits-page-creditbalancecard feature flag */}
            {form8974.tier1Providers.includes(form8974.payrollProvider) && (
              <Tier1OrTier2Form8974SideDrawer
                program={federalRDProgram}
                show={form8974.sideDrawerOpen}
                onCancel={() => form8974.toggleSideDrawer(false)}
                onComplete={() => {
                  form8974.sideDrawerOnComplete(federalRDProgram.id);
                  setComplete(true);
                }}
              />
            )}
          </Flex>
        ) : (
          readyToRedeemInstructions()
        )}
      </Flex>
    );
  },
);
