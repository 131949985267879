import {
  Button,
  Card,
  FileHandle,
  FileUpload,
  Flex,
  Message,
  Text,
  TextField,
  Toast,
} from 'component-library';
import {
  FileTypes,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import { IsValidDate } from 'lib/helpers';
import { observer } from 'mobx-react';
import { CompanyContext } from 'pages/CompanyRequired';
import { IsValidEinNumber } from 'pages/kyc/components/helpers';
import React, { useContext, useState } from 'react';
import { useTaxCreditsStores } from '../../../../../stores/useStores';
import { ClientReviewStepFAQ } from '../../sideDrawer/ClientReviewStepFAQ';
import { Step, StepProps } from '../../TaxCreditProgramSteps';
import { FilingDateTextField } from 'products/tax-credits/features/redemption/components';

export const stepConfig: Step = {
  name: 'client_review',
  number: 3,
  title: 'File your forms with the IRS',
  dataTestId: 'client-review',
  faq: <ClientReviewStepFAQ />,
};

export const ClientReviewStep = observer(
  ({ federalRDProgram, programs }: StepProps) => {
    const { company } = useContext(CompanyContext);
    const { form8974 } = useTaxCreditsStores();

    const [taxFilingDate, setTaxFilingDate] = useState('');
    const [EIN, setEIN] = useState(company.taxId);
    const [toast, setToast] = useState<Message[]>([]);

    const handleUpdateEIN = (value: string) => {
      setEIN(value);
      form8974.setCompanyTaxId(value);
    };

    const filingDate = new Date(taxFilingDate);
    const currentDate = new Date();

    const filingDateError =
      !IsValidDate(taxFilingDate) ||
      !taxFilingDate ||
      ![federalRDProgram.taxYear + 1, federalRDProgram.taxYear + 2].includes(
        filingDate.getFullYear(),
      ) ||
      filingDate > currentDate;

    const disableContinue =
      filingDateError ||
      !IsValidEinNumber(EIN ?? '') ||
      !form8974.filesExistInUploadQueue;

    return (
      <>
        <Card noMargin>
          <Flex direction='column' padding={24}>
            <Text
              variant='medium'
              size={15}
              text='Provide some extra details'
            />
            <Text
              size={15}
              text='So we can best guide you on next steps.'
              paddingBottom={16}
            />
            <FilingDateTextField
              federalRDProgram={federalRDProgram}
              taxFilingDate={taxFilingDate}
              setTaxFilingDate={setTaxFilingDate}
              filingDateError={filingDateError}
            />
            <Flex padding={[24, 0]}>
              <TextField
                dataTestId={'ein-field'}
                helperText='Please confirm the EIN shown on your tax return.'
                einNumberFormat
                label='EIN'
                value={EIN}
                onChange={(e) => handleUpdateEIN(e.target.value)}
              />
            </Flex>
            <Card noMargin noBorder noBoxShadow maxWidth={544}>
              <FileUpload
                dataTestId={'tax-form-file-upload'}
                title='Upload your complete tax return'
                subtitle='It should also include your Form 6765'
                flexDirection={'column'}
                onFileAdded={(file: FileHandle) => {
                  form8974.addFileToBeUploaded(file);
                  file.setUploadProgress(1, 1, 1);
                }}
                acceptedFiletypes={FileTypes.PDF}
                allowFilePassword={false}
                onFileRemoved={(file: FileHandle) =>
                  form8974.removeFileToBeUploaded(file)
                }
                maxFileSize={1024 * 1024 * 30}
                error={false}
                errorText={'error'}
                alignItems='flex-start'
              />
            </Card>
            <Flex padding={[24, 0, 0, 0]}>
              <Button
                small
                flexAlignSelf='flex-start'
                label='Continue'
                dataTestId='continue-button'
                loading={form8974.taxInfoSubmitting}
                disabled={disableContinue}
                onClick={async () => {
                  await form8974.uploadTaxDocsFor8974(federalRDProgram.id);

                  if (!form8974.requestError && form8974.taxInfoSubmitted) {
                    const programsInReview = programs?.filter(
                      (p) =>
                        p.stage === ProgramStageEnum.CLIENT_REVIEW &&
                        p.taxYear === federalRDProgram.taxYear,
                    );

                    if (programsInReview && programsInReview.length > 0) {
                      // update all programs to finished after tax filing date input
                      programsInReview?.forEach(async (p) => {
                        await form8974.updateProgramStage(
                          p.id,
                          ProgramStageEnum.FINISHED,
                          ProgramSubStageEnum.READY_TO_REDEEM,
                        );
                      });
                    }
                  } else {
                    setToast([
                      {
                        text: 'There was an error while uploading your documents. Please try again.',
                        status: 'error',
                      },
                    ]);
                  }
                }}
              />
            </Flex>
          </Flex>
        </Card>
        {toast.length > 0 && <Toast toasts={toast} setToast={setToast} />}
      </>
    );
  },
);
