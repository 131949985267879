import React from 'react';
import { makeStyles, Link } from '@material-ui/core';
import AdapterLink from 'components/util/AdapterLink';
import { Page, TermNames } from 'lib/constants';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { Animate, Color } from 'component-library';

const useStyles = makeStyles(() => ({
  container: {
    width: '200px',
  },
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '200px',
    height: '100vh',
    minHeight: '550px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: Color.neutral._light_20,
    color: Color.neutral._80,
    zIndex: 98,
  },
  smallNav: {
    width: '200px',
  },
  hiddenDiv: {
    width: 0,
    padding: 0,
    margin: 0,
  },
  navLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0',
  },
  navLink: {
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  navLinkActive: {
    pointerEvents: 'none',
    cursor: 'auto',
  },
  linkSection: {
    display: 'flex',
    margin: '10px 0',
    fontWeight: 400,
    width: '100%',
    paddingLeft: '24px',
    boxSizing: 'border-box',
    borderLeft: '6px solid transparent',
    '&:hover': {
      backgroundColor: `${Color.neutral._20}80`,
      borderLeft: `6px solid transparent`,
    },
  },
  navIcon: {
    color: Color.neutral._80,
    textDecoration: 'none',
    fontSize: '20px',
  },
  topLinks: {},
  bottomLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Color.neutral._80,
    fontSize: '14px',
    padding: '24px 0',
  },
  termsLink: {
    textDecoration: 'underline',
  },
  currentPage: {
    display: 'flex',
    margin: '10px 0',
    fontWeight: 500,
    width: '100%',
    paddingLeft: '24px',
    boxSizing: 'border-box',
    borderLeft: `6px solid ${Color.lime._60}`,
    backgroundColor: Color.neutral._20,
  },
  watermarkLink: {
    padding: '30px 0 60px 30px',
  },
  watermark: {
    width: '170px',
  },
  iconmark: {
    width: '50px',
  },
}));

interface NavLink {
  name: string;
  path: string;
  exact?: boolean;
  matches?: string;
}

export const DashboardSideNav = observer(() => {
  const classes = useStyles();
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const showOnboardingTransition = urlParams.get('welcome') === 'true';
  const history = useHistory();
  const { app } = useCommonStores();
  const { company } = useCompany();
  const flags = useFeatureFlags();
  const pathname = history.location.pathname;
  const featureFlags = useFeatureFlags();
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const showNewMSLogo = featureFlags.showNewMainStreetLogo;
  const showUnifiedAssessment = featureFlags.showUnifiedAssessmentExperience;
  const showERCCredit =
    featureFlags.showERCCreditInEstimates &&
    unifiedTaxCredits.hasQualifyingERCProgram();
  const showCreditRedemption =
    featureFlags.showUpdatedCreditsRedemption &&
    unifiedTaxCredits.hasCompletedFedProgram();

  const navLinks: Array<NavLink> = [
    { name: 'Dashboard', path: `/`, exact: true },
    {
      name: showUnifiedAssessment ? 'Tax Credits' : 'R&D Tax Credits',
      path: `/${Page.taxCredits}`,
    },
    {
      name: 'Credits Redemption',
      path: `/${Page.creditsRedemption}`,
    },
    {
      name: 'ERC',
      path: `/${Page.employeeRetentionCredits}`,
    },
    {
      name: 'Employees',
      path: `/${Page.employees}`,
    },
    {
      name: 'Documents',
      path: `/${Page.companyDocs}`,
    },
    {
      name: 'Connections',
      path: `/${Page.connections}`,
    },
    {
      name: 'Refer & Earn',
      path: `/${Page.refer}`,
    },
    {
      name: 'Settings',
      path: `/${Page.settings}`,
    },
  ];

  const showNavLink = (navLink: NavLink) => {
    if (navLink.name === 'Employees') {
      return company.shouldShowEmployeeNav;
    }
    if (navLink.name === 'Refer & Earn') {
      return flags.showReferAndEarn;
    }
    if (navLink.name === 'ERC') {
      return showERCCredit;
    }
    if (navLink.name === 'Shared Savings') {
      return flags.showSharedSavings;
    }

    return true;
  };

  const logoUrl = showNewMSLogo
    ? `${process.env.PUBLIC_URL}/images/logo/mst-logomark-dark.svg`
    : `${process.env.PUBLIC_URL}/images/MainSt_WordmarkGreen.svg`;

  const renderDashboardSideNav = () => {
    // Return a div to fill the grid column when it shouldn't be shown
    if (!app.showSideNav) {
      return (
        <div
          className={classes.hiddenDiv}
          data-testid={'mst-hidden-side-nav'}
        />
      );
    }

    return (
      <div
        className={`${classes.container} ${
          app.isSmallDesktop && classes.smallNav
        }`}
        data-testid={'mst-side-nav'}
      >
        <div
          className={`${classes.root} ${
            app.isSmallDesktop && classes.smallNav
          }`}
        >
          <div className={classes.topLinks}>
            <div className={classes.watermarkLink}>
              <Link component={AdapterLink} color='inherit' to='/'>
                <img
                  className={`${classes.watermark} ${
                    showNewMSLogo && classes.iconmark
                  }`}
                  src={logoUrl}
                  alt='MainStreet watermark'
                />
              </Link>
            </div>
            {navLinks.map((navLink) => {
              const isCurrentPage = navLink.exact
                ? pathname === navLink.path
                : pathname.startsWith(navLink.matches || navLink.path);

              if (!showNavLink(navLink)) {
                return <span key={navLink.name} />;
              }
              return (
                <Link
                  key={navLink.name}
                  data-testid={`sidenav-${navLink.name}`}
                  className={`${classes.navLink} ${
                    isCurrentPage && classes.navLinkActive
                  }`}
                  component={AdapterLink}
                  color='inherit'
                  to={`${navLink.path}`}
                >
                  <div
                    className={
                      isCurrentPage ? classes.currentPage : classes.linkSection
                    }
                  >
                    <div className={classes.navLinkContainer}>
                      {navLink.name}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className={classes.bottomLinks}>
            <Link
              className={classes.termsLink}
              component={AdapterLink}
              color='inherit'
              to={`/${Page.terms}/${TermNames.CORE}`}
            >
              Terms
            </Link>
            &nbsp;and&nbsp;
            <Link
              className={classes.termsLink}
              component={AdapterLink}
              color='inherit'
              to={`/${Page.privacy}`}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showOnboardingTransition ? (
        <Animate enter={'fade-in'} delay={2}>
          {renderDashboardSideNav()}
        </Animate>
      ) : (
        <>{renderDashboardSideNav()}</>
      )}
    </>
  );
});

export default DashboardSideNav;
