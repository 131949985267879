import React from 'react';
import { observer } from 'mobx-react';
import { useFeatureFlags } from 'stores/useStores';
import { TaxCreditPageBody } from './TaxCreditPageBody';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  continueBtn: {
    width: '108px',
  },
}));

export const IneligibleAssessments = observer(() => {
  const featureFlags = useFeatureFlags();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  const bodyTitle = 'Earn new credits';

  const bodySubtitle = (
    <span>
      You did not qualify for any {PROCESSING_TAX_YEAR} credits, but MainStreet
      is here to help you get tax benefits in the future! Here are a few things
      you can do to earn tax&nbsp;credits.
    </span>
  );

  return (
    <TaxCreditPageBody bodyTitle={bodyTitle} bodySubtitle={bodySubtitle} />
  );
});
