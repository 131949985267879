import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Card,
  Color,
  Divider,
  Flex,
  Icon,
  IconEnum,
  Message,
  SideDrawer,
  Spinner,
  Text,
  Toast,
} from 'component-library';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import { useTaxCreditsStores } from 'stores/useStores';
import CheckmarkAnimated from 'components/icons/CheckmarkAnimated';
import { useEffectOnce } from 'lib/helpers';
import { CompanyContext } from 'pages/CompanyRequired';
import { ProgramNameEnum } from 'lib/constants';
import { useDocumentAccessLink } from 'lib/useDocumentAccessLink';
import { CopyToClipboard } from 'lib/copyToClipboard';
import { ViewAllTaxFormsSideDrawer } from './ViewAllTaxFormsSideDrawer';
import { Loading } from '../../../../../components/util/Loading';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  filingInstructions: {
    backgroundColor: Color.neutral._10,

    '& ol': {
      paddingLeft: 12,
      fontSize: '13px',
    },
    '& li': {
      paddingBottom: '8px',
    },
  },
  checkmark: {
    width: 20,
    height: 20,
  },
  confirmCheckbox: {
    maxWidth: '95%',
  },
  filingDateContainer: {
    border: `1px solid ${Color.neutral._20}`,
  },
  copiedLink: {
    border: `1px solid ${Color.green._60}`,
  },
  copyLink: {},
}));

interface TimeToFileStageProps {
  taxYear: number;
}

export const TimeToFileStage = observer(({ taxYear }: TimeToFileStageProps) => {
  const classes = useStyles();
  const { company } = useContext(CompanyContext);
  const { unifiedTaxCredits, form8974 } = useTaxCreditsStores();
  const [toast, setToast] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const federalRDPrograms = company.programs.filter(
    (p) => p.name === ProgramNameEnum.FED_RD_TAX,
  );

  const taxYearFedRDProgram = federalRDPrograms.find(
    (p) => p.taxYear === taxYear,
  );

  useEffectOnce(async () => {
    await form8974.fetchCompanyPayrollProvider(company.id);
    await form8974.getRDCreditSummary();
    await form8974.refreshPrograms();

    await unifiedTaxCredits.getTaxYearDocuments(taxYear);

    setIsLoading(false);
  });

  if (taxYearFedRDProgram) {
    const { accessLink, accessLinkError } = useDocumentAccessLink(
      company.id,
      taxYearFedRDProgram.id,
      taxYear,
    );

    const handleOnCopyLink = () => {
      if (accessLinkError) {
        setToast([
          {
            text: 'There was an error while copying your link. Please try again.',
            status: 'error',
          },
        ]);
      }
      if (accessLink) {
        const onCopied = () => {
          unifiedTaxCredits.setLinkCopied(true);
          setToast([
            {
              text: 'Link, copied!',
              status: 'success',
            },
          ]);
        };
        CopyToClipboard(accessLink, onCopied);
      }
    };

    if (isLoading) {
      return <Loading loading={true} />;
    } else {
      return (
        <>
          <Card noMargin key={`${taxYear}-assessments-client-review`}>
            <Flex direction='column' gap={16} padding={24}>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                wrap='wrap'
              >
                <Flex
                  gap={4}
                  alignItems='center'
                  justifyContent='flex-start'
                  className={classes.title}
                  basis={315}
                >
                  <Text variant='medium' size={18}>
                    Time to file your forms
                  </Text>

                  <Icon
                    name={IconEnum.question_circle}
                    onClick={() =>
                      unifiedTaxCredits.setShowClientTitleSideDrawer(true)
                    }
                  />
                </Flex>
                <Button
                  className={classes.timelineBtn}
                  label={
                    <Flex gap={4}>
                      <Icon
                        name={IconEnum.clock_circle}
                        color={Color.blue._60}
                      />{' '}
                      <Text color={Color.blue._60}>View activity timeline</Text>
                    </Flex>
                  }
                  flexAlignSelf='flex-end'
                  onClick={() =>
                    unifiedTaxCredits.setShowTimelineSideDrawer(true)
                  }
                  variant='tertiary'
                  small
                />
              </Flex>
              <Flex gap={24} direction='column'>
                <Text>
                  Your finalized credit documents are available! File them with
                  your income taxes, then come back to redeem your credits.
                </Text>
                <Flex
                  padding={[24, 24, 0, 24]}
                  direction='column'
                  className={classes.filingInstructions}
                >
                  <Text variant='medium'>Filing instructions</Text>
                  <ol>
                    <li>
                      <Text size={13}>
                        Click on the “Copy link for CPA” button below to copy
                        the magic link.
                      </Text>
                    </li>
                    <li>
                      <Text size={13}>
                        Send the link to your tax preparer, who will file your
                        tax forms with the IRS.
                      </Text>
                    </li>
                    <li>
                      <Text size={13}>
                        After filing taxes, go to ‘Credits Redemption’ to upload
                        your final tax return and redeem your credits.
                      </Text>
                    </li>
                  </ol>
                </Flex>
                <Flex gap={24}>
                  <Button
                    small
                    className={
                      unifiedTaxCredits.linkCopied
                        ? classes.copiedLink
                        : classes.copyLink
                    }
                    onClick={() =>
                      CopyToClipboard(accessLink, handleOnCopyLink)
                    }
                    variant={
                      unifiedTaxCredits.linkCopied ? 'outlined' : 'contained'
                    }
                    label={
                      <Flex alignItems='center' gap={8}>
                        {unifiedTaxCredits.linkCopied && (
                          <Animate enter='fade-in'>
                            <CheckmarkAnimated className={classes.checkmark} />
                          </Animate>
                        )}
                        <Text
                          tag='span'
                          color={
                            unifiedTaxCredits.linkCopied
                              ? Color.green._60
                              : Color.neutral.white
                          }
                          size={13}
                          variant='medium'
                        >
                          {unifiedTaxCredits.linkCopied
                            ? 'Copied'
                            : 'Copy link for CPA'}
                        </Text>
                      </Flex>
                    }
                  />
                  <Button
                    small
                    onClick={() =>
                      (window.location.href = '/credits-redemption')
                    }
                    label='Redeem credits'
                    variant='outlined'
                  />
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <ActivityTimelineSideDrawer
            taxYear={taxYear.toString()}
            stepIndex={unifiedTaxCredits.confirmedFilingCheckbox ? 5 : 4}
          />
          <ViewAllTaxFormsSideDrawer
            taxYear={taxYear.toString()}
            documents={unifiedTaxCredits.viewTaxForms}
          />

          <SideDrawer
            show={unifiedTaxCredits.showClientTitleSideDrawer}
            title='File your forms with the IRS'
            closeToggle={() =>
              unifiedTaxCredits.setShowClientTitleSideDrawer(false)
            }
            drawerContent={
              <Flex direction='column' gap={16}>
                <Text variant='medium'>What&apos;s this step?</Text>
                <Divider variant='no-bottom-margin' />
                <Text>
                  After receiving your credit forms from MainStreet, you must
                  file them with your business income taxes.
                </Text>
                <Text>There are two ways to redeem the credits:</Text>
                <ul>
                  <li>
                    <Text paddingBottom={16}>
                      <strong>Income tax credit:</strong> Your credit will
                      instantly reduce your business income tax liability. You
                      don&apos;t need to do anything else after filing the form
                      that we sent to you alongside your business income taxes.
                    </Text>
                  </li>

                  <li>
                    <Text>
                      <strong>Payroll tax credit:</strong> In the quarter after
                      you&apos;ve filed Form 6765, you&apos;ll need to file a
                      Form 8974 with your quarterly payroll taxes in order to
                      redeem your credits.
                    </Text>
                  </li>
                </ul>
                <Text>
                  Don&apos;t worry, we&apos;ll let you know exactly what to do
                  once we get to this step.
                </Text>
              </Flex>
            }
          />
          {toast.length > 0 && <Toast toasts={toast} setToast={setToast} />}
        </>
      );
    }
  } else {
    return (
      <Flex alignItems='center' justifyContent='center' padding={16}>
        <Spinner size='small' />
      </Flex>
    );
  }
});
