import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './Discover.module.scss';
import { makeStyles } from '@material-ui/core';
import {
  useCommonStores,
  useDashboardStores,
  useFeatureFlags,
  useOnboardingStores,
} from 'stores/useStores';
import confetti from 'canvas-confetti';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { Color, FontAwesome } from 'component-library';
import AnimatedBackground from './components/animated-background/animatedBackground';
import Button from './components/button/button';
import Text from './components/text/text';
import Flex from './components/flex/flex';
import Grid from './components/grid/grid';
import Heading from './components/heading/heading';
import { AnimatePresence, motion } from 'framer-motion';
import Loader from './components/loader/loader';
import { CentsToDisplayString, Credits, CreditsName } from './utils/constants';
import IntersectionObserverComponent from './components/intersection-observer/intersectionObserver';
import FooterSection from './components/FooterSection';
import { TalkToAnExpertModal } from 'components/TalkToAnExpertModal';
import { SignUpModal } from 'products/onboarding/features/welcome-to-mainstreet/components/SignUpModal';
import { IRSGuidelinesSection } from 'products/onboarding/features/welcome-to-mainstreet/components/sections';

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      fontSize: '10px',
    },
    html: {
      fontSize: '10px',
    },
  },
  root: {
    position: 'relative',
  },
}));

export const DiscoverPage = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const [showNavBg, setShowNavBg] = useState<boolean>(false);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const [showMadlib, setShowMadlib] = useState<boolean>(false);
  const { app } = useCommonStores();
  const { discover, modules } = useDashboardStores();
  const { initialAccountSetup } = useOnboardingStores();
  const [showCalendly, setShowCalendly] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      container.style.setProperty('--mouse-x', `${x}px`);
      container.style.setProperty('--mouse-y', `${y}px`);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShowNavBg(true);
    } else {
      setShowNavBg(false);
    }
  };

  const selectedCreditEstimates = discover.selectedCredits.filter(
    (credit) => credit.checked && credit.estimateCents > 0,
  );

  const singleCredit = selectedCreditEstimates.length === 1;
  const handleClaimButton = () => {
    discover.setLoadingClaim(true);
    const setConfettiTimer = setTimeout(() => {
      const confettiCanvas = document.createElement('canvas');
      const confettiPosition = document.getElementById('confetti');

      if (confettiPosition) {
        const hasCanvasChild = Array.from(confettiPosition.children).some(
          function (child) {
            return child.nodeName === 'CANVAS';
          },
        );

        // only create if canvas doesn't exist
        if (!hasCanvasChild) {
          confettiPosition.appendChild(confettiCanvas);
        }
      }

      const myConfetti = confetti.create(confettiCanvas, {
        useWorker: true,
      });
      myConfetti({
        particleCount: 70,
        spread: 100,
        startVelocity: 10,
        scalar: 0.4,
        gravity: 0.5,
        origin: { y: 0.5, x: 0.5 },
      });
    }, 0);

    const continueTimer = setTimeout(() => {
      modules.toggleSignUpModal(true);
      setShowMadlib(false);
    }, 1000);

    const cancelAllStates = setTimeout(() => {
      discover.setLoadingClaim(false);
      discover.setShowEstimate(false);
    }, 1500);

    return () => {
      clearTimeout(setConfettiTimer);
      clearTimeout(continueTimer);
      clearTimeout(cancelAllStates);
    };
  };

  // currency mask
  const currencyMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    allowNegative: false,
    allowLeadingZeroes: true,
  };

  // numeric mask
  const numericMaskOption = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    allowLeadingZeroes: true,
  };

  const numericMask = createNumberMask(numericMaskOption);
  const currencyMask = createNumberMask(currencyMaskOptions);

  const handleInputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'companyName' | 'yearFounded' | 'employeeCount' | 'payrollSpent',
  ) => {
    switch (field) {
      case 'companyName':
        discover.setCompanyName(e.target.value);
        break;
      case 'yearFounded':
        discover.setYearFounded(e.target.value);

        break;
      case 'employeeCount':
        discover.setEmployeeCount(e.target.value);
        break;
      case 'payrollSpent':
        discover.setPayrollSpent(e.target.value);
        break;
    }
  };

  const handleSeeEstimates = async () => {
    await discover.handleSeeEstimates();

    const setLoadingTimer = await setTimeout(() => {
      discover.setShowEstimate(true);
      discover.setLoadingEstimates(false);
    }, 3000);

    return () => clearTimeout(setLoadingTimer);
  };

  useEffect(() => {
    if (discover.employeeCount.length > 0 && discover.payrollSpent.length > 0) {
      discover.setDisabledContinueBtn(false);
    }
  }, [discover.employeeCount, discover.payrollSpent, discover]);

  useEffect(() => {
    const updateScroll = () => {
      window.requestAnimationFrame(handleScroll);
    };
    const showMadlib = setTimeout(() => {
      setShowMadlib(true);
      setInitialLoader(true);
    }, 6000);

    window.addEventListener('scroll', updateScroll);
    return () => {
      window.removeEventListener('scroll', updateScroll);
      clearTimeout(showMadlib);
    };
  }, []);

  return (
    <main className={`${styles.mainContainer} ${classes.root}`}>
      <IntersectionObserverComponent
        className={styles.sectionInView}
        inViewClassName={'section-in-view'}
        threshold={0.05}
      >
        <>
          <nav className={`${styles.nav} ${showNavBg ? styles.scrolled : ''}`}>
            <div
              className={`${styles.loader} ${
                initialLoader ? styles.showMadlib : ''
              }`}
            />
            <Flex.Cell className={styles.logoGroup}>
              <img
                src={'/images/logo/mst-logo-light.svg'}
                width={120}
                height={42}
                alt='MainStreet Logo'
                className={styles.lightLogo}
              />
              <img
                src={'/images/logo/mst-logo-dark.svg'}
                width={120}
                height={42}
                alt='MainStreet Logo'
                className={styles.darkLogo}
              />
            </Flex.Cell>
            <Flex alignItems='center' justifyContent='flex-end' gap={24}>
              <Button
                label={'Log In'}
                onClick={() => app.history.push('/')}
                dataTestId='log-in'
                variant='tertiary'
                className={styles.logInBtn}
                padding={[12, 0]}
              />
              {!app.isTablet800 && (
                <Button
                  label={'Discover estimates'}
                  onClick={() => setShowMadlib(true)}
                  dataTestId='discover-estimates'
                  padding={[8, 24]}
                  lime
                  rounded
                  disabled={showMadlib}
                />
              )}
            </Flex>
          </nav>
          <div
            className={styles.container}
            ref={containerRef}
            onMouseMove={onMouseMove}
          >
            <div className={styles.animatedBackground}>
              <AnimatedBackground />
            </div>

            <Flex className={styles.mainContent} direction='column' gap={24}>
              <Flex
                className={`${styles.introHeading} ${
                  showMadlib ? styles.showMadlib : ''
                }`}
                direction='column'
                gap={24}
              >
                <Flex direction='column'>
                  <Heading color={Color.neutral.white}>
                    Save{' '}
                    <Heading tag='span' color={Color.lime._60}>
                      thousands
                    </Heading>{' '}
                    on business&nbsp;taxes.
                  </Heading>
                </Flex>
                <Heading
                  color={Color.neutral.white}
                  tag='h4'
                  className={styles.subtitle}
                >
                  Your full potential is buried in 77,000+&nbsp;pages of
                  tax&nbsp;code. We uncover more for you and
                  your&nbsp;employees.
                </Heading>
                <Flex
                  gap={app.isMobile640 ? 12 : 24}
                  padding={[12, 0]}
                  className={styles.buttonGroup}
                  alignItems='center'
                  justifyContent='center'
                  direction={app.isMobile640 ? 'column' : 'row'}
                >
                  <Button
                    label='Discover estimates'
                    dataTestId='discover-estimates'
                    onClick={() => setShowMadlib(true)}
                    lime
                    rounded
                  />
                  {featureFlags.showTalkToAnExpertButton && (
                    <Button
                      label={
                        <Flex gap={8} alignItems='center'>
                          <FontAwesome
                            name='video'
                            color={Color.neutral._20}
                            size={16}
                          />
                          <Text color={Color.neutral._20}>
                            Talk to an expert
                          </Text>
                        </Flex>
                      }
                      dataTestId='talk-to-expert'
                      onClick={() =>
                        initialAccountSetup.setShowTalkToExpertModal(true)
                      }
                      variant='tertiary'
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className={`${styles.estimateContainer} ${
                showMadlib ? styles.showMadlib : ''
              }`}
              direction='column'
            >
              <Flex
                className={`${styles.estimateInner} ${
                  discover.showEstimates ? styles.showEstimates : ''
                }`}
                alignItems='center'
              >
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                  direction='column'
                  gap={32}
                  style={!discover.showEstimates ? { height: '100%' } : {}}
                >
                  {!discover.showEstimates && (
                    <motion.div
                      key='ms-logo'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Flex justifyContent='center'>
                        <img
                          src={'/images/logo/mst-logomark-green.svg'}
                          width={48}
                          height={48}
                          alt='MainStreet Logo'
                          className={styles.logomark}
                        />
                      </Flex>
                    </motion.div>
                  )}
                  <AnimatePresence exitBeforeEnter>
                    {discover.loadingEstimates ? (
                      <>
                        <motion.div
                          key='loading-estimates'
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          style={{
                            height: '100%',
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Flex justifyContent='center' alignItems='center'>
                            <Loader />
                          </Flex>
                        </motion.div>
                        <div />
                      </>
                    ) : discover.showEstimates ? (
                      <motion.div
                        key='show-estimates'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{ width: '100%' }}
                      >
                        <Flex
                          justifyContent='center'
                          alignItems='center'
                          className={styles.estimatesContainer}
                        >
                          <Flex
                            className={styles.estimateContent}
                            gap={app.isTablet960 ? 24 : 40}
                            padding={
                              app.isTablet960 ? [0, 12, 32, 12] : [0, 24]
                            }
                            direction={app.isTablet960 ? 'column' : 'row'}
                          >
                            <Flex
                              alignItems='center'
                              className={styles.contentCta}
                            >
                              <Flex
                                direction='column'
                                gap={app.isTablet960 ? 8 : 12}
                              >
                                <img
                                  src={'/images/logo/mst-logomark-green.svg'}
                                  width={48}
                                  height={48}
                                  alt='MainStreet Logo'
                                  className={styles.logomark}
                                />
                                <Heading tag='h4'>
                                  Here {singleCredit ? 'is' : 'are some'} of the{' '}
                                  {singleCredit ? 'saving' : 'savings'} you
                                  could be eligible&nbsp;for.
                                </Heading>
                                <Heading
                                  tag='h5'
                                  marginBottom={app.isTablet960 ? 8 : 16}
                                >
                                  Are you ready to claim{' '}
                                  {discover.totalCreditEstimates &&
                                  !singleCredit ? (
                                    <>
                                      an estimated total of{' '}
                                      <Heading
                                        tag='span'
                                        variant='medium'
                                        color={Color.semantic.$success50}
                                      >
                                        {CentsToDisplayString(
                                          discover.totalCreditEstimates,
                                          0,
                                          0,
                                        )}
                                      </Heading>{' '}
                                      in tax{' '}
                                      {singleCredit ? 'saving' : 'savings'}?
                                    </>
                                  ) : (
                                    <>
                                      {singleCredit
                                        ? 'this saving'
                                        : 'these savings'}
                                      ? It&apos;ll&nbsp;only&nbsp;take a
                                      few&nbsp;minutes!
                                    </>
                                  )}
                                </Heading>
                                <Flex className={styles.claimBtn}>
                                  <div
                                    id='confetti'
                                    className={styles.confetti}
                                  />
                                  <Button
                                    label={'Ready to Claim'}
                                    onClick={() => handleClaimButton()}
                                    dataTestId='claim-credit-estimates'
                                    flexAlignSelf='flex-start'
                                    loading={discover.loadingClaim}
                                  />
                                </Flex>
                              </Flex>
                            </Flex>
                            <Flex
                              className={styles.estimateCredits}
                              justifyContent='center'
                              direction='column'
                              gap={12}
                            >
                              {selectedCreditEstimates.map((credit, index) => {
                                return (
                                  <motion.div
                                    key={`${credit.text}-${index}`}
                                    initial={{ opacity: 0, x: 24 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 24 }}
                                    transition={{
                                      duration: 0.4,
                                      delay: (index + 2) * 0.2,
                                    }}
                                    className={styles.estimateList}
                                  >
                                    <Flex
                                      justifyContent='center'
                                      direction='column'
                                      gap={4}
                                    >
                                      <Text
                                        variant='medium'
                                        size={16}
                                        color={Color.green._80}
                                      >
                                        {credit.text}
                                      </Text>
                                    </Flex>
                                    <Flex
                                      direction='column'
                                      style={{ width: 'auto' }}
                                    >
                                      {credit.text ===
                                        CreditsName.DISABLED_ACCESS && (
                                        <Text
                                          size={12}
                                          textAlign='right'
                                          color={Color.neutral._90}
                                          variant='medium'
                                        >
                                          Up to
                                        </Text>
                                      )}
                                      {credit.estimateCents ? (
                                        <Text
                                          color={Color.green._80}
                                          variant='medium'
                                          className={styles.estimateValue}
                                          textAlign='right'
                                        >
                                          {CentsToDisplayString(
                                            credit.estimateCents,
                                            0,
                                            0,
                                          )}
                                          *
                                        </Text>
                                      ) : (
                                        <Text
                                          color={Color.green._80}
                                          variant='medium'
                                          className={styles.estimateValue}
                                          textAlign='right'
                                        >
                                          None
                                        </Text>
                                      )}
                                    </Flex>
                                  </motion.div>
                                );
                              })}
                              <Text size={12} color={Color.neutral._80}>
                                *Early estimates based on what you&apos;ve told
                                us about your company.
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </motion.div>
                    ) : (
                      <motion.div
                        key='enter-values'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.innerContent}
                      >
                        <Flex className={styles.innerContent}>
                          <Flex direction='column'>
                            <Heading tag='h4' variant='medium'>
                              Tell us a few&nbsp;things
                            </Heading>
                            <Text>We&apos;ll help you unlock tax savings.</Text>
                          </Flex>
                          <Flex
                            gap={12}
                            className={styles.inputValues}
                            direction='column'
                          >
                            <Flex
                              direction='column'
                              className={`${styles.inputField} ${
                                discover.employeeCount.length > 0
                                  ? styles.filled
                                  : ''
                              }`}
                            >
                              <Text>Number of employees</Text>
                              <Flex.Cell className={styles.input}>
                                <MaskedInput
                                  value={discover.employeeCount}
                                  onChange={(e) =>
                                    handleInputOnChange(e, 'employeeCount')
                                  }
                                  mask={numericMask}
                                />
                              </Flex.Cell>
                            </Flex>
                            <Flex
                              direction='column'
                              className={`${styles.inputField} ${
                                discover.payrollSpent.length > 0
                                  ? styles.filled
                                  : ''
                              }`}
                            >
                              <Text>Estimated annual payroll</Text>
                              <Flex.Cell
                                className={`${styles.input} ${styles.currencyInput}`}
                              >
                                <span>$</span>
                                <MaskedInput
                                  value={discover.payrollSpent}
                                  onChange={(e) =>
                                    handleInputOnChange(e, 'payrollSpent')
                                  }
                                  mask={currencyMask}
                                />
                              </Flex.Cell>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex
                          gap={8}
                          alignItems='center'
                          direction='column'
                          className={styles.btnGroup}
                        >
                          <Button
                            label={'See estimates'}
                            onClick={async () => handleSeeEstimates()}
                            dataTestId='submit-adlib'
                            flexAlignSelf='center'
                            disabled={discover.disabledContinueBtn}
                            loading={discover.loadingEstimates}
                            padding={[12, 16]}
                          />
                          <Button
                            label="I don't like free money"
                            dataTestId='discover-later'
                            onClick={() => setShowMadlib(false)}
                            variant='tertiary-dark'
                            padding={12}
                          />
                        </Flex>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Flex>
              </Flex>
            </Flex>
          </div>
        </>
      </IntersectionObserverComponent>
      <Flex
        justifyContent='center'
        className={`${styles.section} ${styles.claimCredits}`}
        dataTestId='discover'
      >
        <Flex className={`${styles.sectionContent} ${styles.claimCredits}`}>
          <Flex justifyContent='center' direction='column' gap={48}>
            <Heading tag='h3'>
              We help your business&nbsp;claim the following&nbsp;credits
              and&nbsp;more
            </Heading>
            <Flex justifyContent='center' alignItems='center'>
              <Grid columns={8} gap={24}>
                {Credits.map((credit, index) => {
                  return (
                    <Grid.Cell
                      key={`tax-credits-${index}`}
                      columns={app.isMobile640 ? 4 : 2}
                    >
                      <Flex direction='column' alignItems='center' gap={8}>
                        <Flex.Cell basis={app.isMobile640 ? 24 : 50}>
                          <img
                            src={credit.icon}
                            width={app.isMobile640 ? 24 : 48}
                            height={app.isMobile640 ? 24 : 48}
                            alt={`${credit.text} icon`}
                          />
                        </Flex.Cell>
                        <Flex direction='column' gap={4}>
                          <Text textAlign='center' variant='medium'>
                            {credit.text}
                          </Text>
                        </Flex>
                      </Flex>
                    </Grid.Cell>
                  );
                })}
              </Grid>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent='center'
        className={`${styles.section} ${styles.howItWorks}`}
      >
        <Flex
          className={`${styles.sectionContent} ${styles.howItWorks}`}
          justifyContent='center'
          direction='column'
          gap={64}
        >
          <Flex direction='column' alignItems='center' gap={4}>
            <Heading tag='h5' color={Color.green._80} variant='medium'>
              How it works
            </Heading>
            <Heading tag='h3'>
              In partnership with your&nbsp;CPA, we&nbsp;maximize
              your&nbsp;tax&nbsp;credits.
            </Heading>
          </Flex>
          <Flex justifyContent='center' alignItems='center'>
            <Grid columns={12} gap={app.isTablet960 ? 24 : 80}>
              {howItWorks.map((item, index) => {
                return (
                  <Grid.Cell
                    key={`tax-credits-${index}`}
                    columns={app.isTablet800 ? 12 : 4}
                  >
                    <Flex
                      direction='column'
                      alignItems='center'
                      gap={app.isMobile640 ? 24 : 40}
                    >
                      <Flex.Cell basis={app.isMobile640 ? 24 : 50}>
                        <img
                          src={item.icon}
                          width={app.isMobile640 ? 60 : 80}
                          height={app.isMobile640 ? 60 : 80}
                          alt={`${item.title} icon`}
                        />
                      </Flex.Cell>
                      <Flex direction='column' gap={app.isMobile640 ? 8 : 24}>
                        <Heading tag='h4' textAlign='center'>
                          {item.title}
                        </Heading>
                        <Text textAlign='center'>{item.subtitle}</Text>
                      </Flex>
                    </Flex>
                  </Grid.Cell>
                );
              })}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ backgroundColor: Color.neutral._10 }}
        padding={[0, 0, 40, 0]}
      >
        <IRSGuidelinesSection />
      </Flex>
      <FooterSection />
      <SignUpModal
        title='Start saving today'
        subtitle="Unlock your greatest cash flow potential now. You'll only pay when you save."
        cta='Create account'
      />

      <TalkToAnExpertModal
        setShowCalendly={setShowCalendly}
        showCalendly={showCalendly}
      />
    </main>
  );
});

const howItWorks = [
  {
    title: <>Confirm your final credit&nbsp;amount</>,
    subtitle: (
      <>
        Just connect your payroll&nbsp;data and confirm
        a&nbsp;few&nbsp;questions.
      </>
    ),
    icon: '/images/logo/confirm-credit-amount-icon.svg',
  },
  {
    title: <>Easy handoff to your&nbsp;CPA</>,
    subtitle: (
      <>
        We provide forms for all credits you might be qualified for. Send them
        to your&nbsp;CPA to be included in your income&nbsp;taxes.
      </>
    ),
    icon: '/images/logo/easy-handoff-cpa-icon.svg',
  },
  {
    title: <>Successfully claim your&nbsp;credits</>,
    subtitle: (
      <>
        Depending on the credit, you&apos;ll either offset payroll or income
        taxes directly. We only charge 20% of the final&nbsp;credit on
        your&nbsp;forms.
      </>
    ),
    icon: '/images/logo/start-saving-icon.svg',
  },
];
