import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseDashboardStore } from '../BaseDashboardStore';
import {
  CalculateRetirementEstimate,
  Credits,
  CreditsName,
  CreditsOption,
  CurrencyStringToCents,
} from '../features/discover/utils/constants';

export class DiscoverStore extends BaseDashboardStore {
  public companyName = 'Netflix';
  public yearFounded = '2020';
  public employeeCount = '';
  public payrollSpent = '';
  public taxType = 'S Corp';
  public yearEndMonth = 'December';
  public loadingEstimates = false;
  public showEstimates = false;
  public selectedCredits: CreditsOption[] = Credits;
  public disabledContinueBtn = true;
  public totalCreditEstimates: number | null = null;
  public loadingClaim = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setCompanyName(str: string) {
    runInAction(() => {
      this.companyName = str;
    });
  }

  public setYearFounded(str: string) {
    runInAction(() => {
      this.yearFounded = str;
    });
  }

  public setEmployeeCount(str: string) {
    runInAction(() => {
      this.employeeCount = str;
    });
  }

  public setPayrollSpent(str: string) {
    runInAction(() => {
      this.payrollSpent = str;
    });
  }

  public setTaxType(str: string) {
    runInAction(() => {
      this.taxType = str;
    });
  }

  public setYearEndMonth(str: string) {
    runInAction(() => {
      this.yearEndMonth = str;
    });
  }

  public setSelectedCredits(credits: CreditsOption[]) {
    runInAction(() => {
      this.selectedCredits = credits;
    });
  }

  public setLoadingEstimates(bool: boolean) {
    runInAction(() => {
      this.loadingEstimates = bool;
    });
  }

  public setLoadingClaim(bool: boolean) {
    runInAction(() => {
      this.loadingClaim = bool;
    });
  }

  public setShowEstimate(bool: boolean) {
    runInAction(() => {
      this.showEstimates = bool;
    });
  }

  public setDisabledContinueBtn(bool: boolean) {
    runInAction(() => (this.disabledContinueBtn = bool));
  }

  public async handleSeeEstimates() {
    this.setLoadingEstimates(true);

    const employeeCount = Number(this.employeeCount);
    const DACEligible = employeeCount < 35;
    const retirementEstimate = CalculateRetirementEstimate(employeeCount);
    const rdEstimate = CurrencyStringToCents(this.payrollSpent) * 0.08;
    const dqHealthCareTotalPayCheck =
      CurrencyStringToCents(this.payrollSpent) / employeeCount > 6200000;

    const updateCreditEstimates = this.selectedCredits?.map(
      (checkboxAnswer) => {
        if (
          checkboxAnswer.text === CreditsName.DISABLED_ACCESS &&
          DACEligible
        ) {
          return { ...checkboxAnswer, checked: true };
        } else if (
          checkboxAnswer.text === CreditsName.DISABLED_ACCESS &&
          !DACEligible
        ) {
          return { ...checkboxAnswer, checked: false };
        }

        if (
          employeeCount < 100 &&
          checkboxAnswer.text === CreditsName.RETIREMENT_PLAN
        ) {
          return {
            ...checkboxAnswer,
            estimateCents: retirementEstimate,
          };
        } else if (
          employeeCount >= 100 &&
          checkboxAnswer.text === CreditsName.RETIREMENT_PLAN
        ) {
          return {
            ...checkboxAnswer,
            estimateCents: 0,
            checked: false,
          };
        }

        if (checkboxAnswer.text === CreditsName.RD) {
          return {
            ...checkboxAnswer,
            estimateCents: rdEstimate,
          };
        }

        if (
          (employeeCount > 24 || dqHealthCareTotalPayCheck) &&
          checkboxAnswer.text === CreditsName.HEALTHCARE
        ) {
          return {
            ...checkboxAnswer,
            estimateCents: 0,
            checked: false,
          };
        } else if (checkboxAnswer.text === CreditsName.HEALTHCARE) {
          return {
            ...checkboxAnswer,
            estimateCents: 1112000,
          };
        }

        return checkboxAnswer;
      },
    );

    runInAction(() => {
      // const removeWorkFromHome = this.workFromHome
      //   ? updateCreditEstimates
      //   : updateCreditEstimates.filter(
      //       (c) => c.text !== CreditsName.WORK_FROM_HOME,
      //     );

      // console.log(
      //   this.workFromHome,
      //   updateCreditEstimates,
      //   'this.workFromHome',
      // );

      this.setSelectedCredits(updateCreditEstimates);

      const selectedCreditEstimates = updateCreditEstimates.filter(
        (credit) => credit.checked,
      );

      let totalEstimate = 0;
      selectedCreditEstimates.forEach((credit) => {
        totalEstimate += credit.estimateCents;
      });

      this.totalCreditEstimates = totalEstimate;
    });
  }
}
