import { datadogLogs } from '@datadog/browser-logs';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Card,
  Checkbox,
  Color,
  Content,
  Divider,
  Flex,
  Link,
  Modal,
  Text,
} from 'component-library';
import { ChargeBeePaymentForm } from 'components/payments/ChargeBeePaymentForm';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import LoadingWidget from 'components/util/LoadingWidget';
import { Page, TermNames } from 'lib/constants';
import { useEffectOnce } from 'lib/helpers';
import { useTakeScreenshot } from 'lib/useTakeScreenshot';
import { observer } from 'mobx-react';
import { AddPaymentMethod } from 'products/tax-credits/features/order-form/components';
import React, { LegacyRef, useContext, useEffect, useState } from 'react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
  },
  header: {
    paddingTop: '24px',
    paddingLeft: '48px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '12px',
    },
  },
  headerTitle: {
    color: '#0A1A0C',
  },
  subtitleText: {
    whiteSpace: 'nowrap',
    fontSize: '22px',
    display: 'flex',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  verticalLine: {
    height: '30px',
    width: '2px',
    backgroundColor: '#F0F0F0',
    margin: '0 12px',
  },
  horizontalLine: {
    height: '1px',
    backgroundColor: '#F0F0F0',
    maxWidth: '100%',
    margin: '20px 0',
  },
  horizontalDashes: {
    height: '1px',
    maxWidth: '100%',
    borderTop: '2px dashed #6D8AED',
    marginTop: '10px',
    paddingBottom: '5px',
  },
  acceptanceBanner: {
    height: '42px',
    backgroundColor: '#004631',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  acceptanceBannerText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 15,
  },
  contentContainer: {
    padding: '24px 48px 24px 148px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
    marginBottom: '36px',
  },
  contentHeaderText: {
    color: '#005229',
    fontWeight: 700,
    fontSize: 36,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  contentImage: {
    width: '160px',
    objectFit: 'contain',
  },
  contentTitle: {
    fontWeight: 700,
  },
  contentItalic: {
    fontStyle: 'italic',
  },
  leftColumn: {
    width: '550px',
    '@media (max-width: 1000px)': {
      width: '100%',
    },
  },
  rightColumn: {
    maxHeight: '1000px',
    width: '600px',
    '@media (max-width: 1000px)': {
      width: '100%',
      maxHeight: '1000px',
    },
  },
  paymentCard: {
    width: '556px',
    maxHeight: '1000px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 1000px)': {
      width: '100%',
    },
  },
  paymentContent: {
    width: '556px',
    '@media (max-width: 1000px)': {
      width: '100%',
    },
  },
  amountDue: {
    backgroundColor: Color.blue._10,
    borderColor: Color.blue._50,
    border: '1px solid',
    borderRadius: '8px',
    padding: '20px 24px 20px 24px',
  },
  dueText: {
    fontWeight: 600,
    size: 18,
    lineHeight: '24px',
  },
  amountText: {
    fontWeight: 600,
    size: 28,
    lineHeight: '36px',
  },
  startSavingButton: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  paymentMessage: {
    marginLeft: '32px',
    paddingRight: '32px',
    marginBottom: '16px',
    '@media (max-width: 1000px)': {
      marginLeft: '16px',
    },
  },
  finePrint: {
    maxWidth: '1200px',
  },
}));

export const SavingsAgreement = observer(() => {
  const classes = useStyles();
  const featureFlags = useFeatureFlags();
  const showChargebeePaymentMethod = featureFlags.showChargebeePaymentMethod;
  const [paymentMethod, setPaymentMethod] = useState<boolean>(false);
  const { app, companyStore } = useCommonStores();
  const company = companyStore.company;
  const { client } = useContext(Auth0FeatureContext);

  const logger = datadogLogs.createLogger('SavingsAgreement');

  const { takeScreenshotAndSave, ref } = useTakeScreenshot(
    client,
    'savings-discovery-agreement-screenshot',
    'jpg',
    'savings discovery agreement screenshot',
  );

  const [acceptanceBannerVisible, setAcceptanceBannerVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [transtionModalVisible, setTransitionModalVisible] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);

  const termsIncluded: string[] = [
    // Names of terms to be included in the agreement
    TermNames.CORE,
    TermNames.PROMOTIONAL,
  ];

  const [termsPresent, setTermsPresent] = useState([
    <Link
      key={0}
      href='https://dashboard.mainstreet.com/terms-of-service'
      target='_blank'
    >
      core terms of service
    </Link>,
  ]);

  const date = new Date();
  const startDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });

  const savingBenefitCards = [
    {
      icon: `${process.env.PUBLIC_URL}/images/accounting-with-shadow.svg`,
      title: 'Business Tax Credits',
      description:
        'MainStreet identifies and generates tax credits that can save you on employment costs and various eligible expenses.',
    },
    {
      icon: `${process.env.PUBLIC_URL}/images/credit-card-with-shadow.svg`,
      title: 'Pay-As-You-Save',
      description: (
        <>
          Pay only for the benefits you realize, with no upfront fees. We charge
          you based on the value you receive, with fees capped at $300 per
          employee per month to ensure predictability. You can cancel at
          anytime. For more detailed information{' '}
          <Link href='https://mainstreet.com/77k-pricing/' target='_blank'>
            click here
          </Link>
          . <br />
          <br />
          <Text size={13} tag='span' className={classes.contentItalic}>
            *You will not be charged any amount if no services have been
            delivered. If you cancel, you will be billed for all expected value
            of delivered services.
          </Text>
        </>
      ),
    },
    {
      icon: `${process.env.PUBLIC_URL}/images/tax-form-with-shadow.svg`,
      title: 'Effortless Savings',
      description: `It's a no-brainer. MainStreet automatically delivers tax savings for you and your employees. You have the power to opt out of any savings you don't want, and we'll take care of the rest!`,
    },
    {
      icon: `${process.env.PUBLIC_URL}/images/coins-with-shadow.svg`,
      title: 'MainStreet Guarantee',
      description: `In the unlikely event of an audit, we'll reimburse you up to $1,000,000 for any accountant fees, penalties or interest you have to pay to the government due to a MainStreet error.`,
    },
  ];

  useEffectOnce(() => {
    client.GetTermsByNames(termsIncluded).then((res) => {
      const termLinks = convertTermstoLinks(res.data.terms);
      if (termLinks.length > 0) {
        setTermsPresent(termLinks);
      }
    });
  });

  const convertTermstoLinks = (terms: any) => {
    return terms.map((term: any) => (
      <Link key={term.id} href={`/terms/${term.name}`} target='_blank'>
        {term.name}
        {' v'}
        {term.termsVersions[0].versionNumber}
      </Link>
    ));
  };

  const submitForm = async () => {
    setAcceptanceBannerVisible(true);
  };

  // Happens when start saving button is clicked
  useEffect(() => {
    if (acceptanceBannerVisible) {
      setOnSubmit(true);
      takeScreenshotAndSave()
        .then(async () => {
          logger.info(
            `${company.name} with id ${company.id} has accepted the savings agreement`,
          );
          setTransitionModalVisible(true);
          await client.CaptureTermsAcceptance(termsIncluded);
        })
        .catch((err) => {
          setAcceptanceBannerVisible(false);
          setOnSubmit(false);
          logger.error(
            `Error saving terms acceptance for ${company.name}`,
            err,
          );
        });
    }
  }, [acceptanceBannerVisible]); // Empty dependency array ensures this runs only once after initial render

  // Happens when the transition modal is visible and will redirect to /connections
  useEffect(() => {
    if (transtionModalVisible) {
      setTimeout(() => {
        app.history.push(`/${Page.taxCredits}`);
      }, 3000);
    }
  }, [transtionModalVisible]);

  const showFinePrint = () => {
    return (
      <>
        <Flex direction='column' alignItems='flex-start'>
          <Text size={13}>
            *Pay-as-you-save is offered on a promotional basis. You will be
            enrolled in a platform subscription at $300/month/employee.
            Promotional credits are automatically applied to ensure you only pay
            for the value you actually receive. Value delivery will be based on
            MainStreet&apos;s estimation until you provide information to
            confirm otherwise. Requires your participation to determine exact
            realized savings amounts. If you cancel your subscription or fail to
            follow relevant filing instructions, you may be billed for the
            expected value of delivered services. Refer to terms for more
            details.
          </Text>
        </Flex>
      </>
    );
  };

  return (
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      className={classes.root}
      data-testid={'savings-agreement'}
    >
      {/* Header */}
      <Flex
        direction='row'
        justifyContent='flex-start'
        gap={app.isMobile ? 4 : 16}
        alignItems='center'
        className={classes.header}
      >
        <h2 className={classes.headerTitle}>MainStreet</h2>
        <div className={classes.verticalLine}></div>
        <div className={classes.subtitleText}>Savings Discovery Agreement</div>
      </Flex>

      {acceptanceBannerVisible && (
        <Flex
          direction='row'
          className={classes.acceptanceBanner}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text size={15} className={classes.acceptanceBannerText}>
            {company?.adminName.first} {company?.adminName.last} (
            {company.adminEmail}) accepted on{' '}
            {new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}{' '}
            at{' '}
            {new Date().toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
          </Text>
        </Flex>
      )}
      <Divider variant='full-width' />
      <Flex
        gap={app.isMobile ? 16 : 48}
        direction={app.isTablet ? 'column' : 'row'}
        className={classes.contentContainer}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex direction='column' gap={16} className={classes.leftColumn}>
          <div className={classes.contentHeaderText}>Pay As You Save</div>

          <Text size={15}>Agreement Start Date: {startDate}</Text>
          {savingBenefitCards.map((card, index) => (
            <React.Fragment key={index}>
              {app.isMobile ? <></> : <Divider variant='full-width' />}
              <Flex direction='row'>
                <Flex
                  direction='column'
                  alignItems={'flex-start'}
                  className={classes.contentImage}
                >
                  <img src={card.icon} alt='Icon' />
                </Flex>
                <Flex direction='column' gap={16}>
                  <Text className={classes.contentTitle} size={18}>
                    {card.title}
                  </Text>
                  <Text size={15}>{card.description}</Text>
                </Flex>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
        <Flex
          className={classes.rightColumn}
          style={{ alignSelf: 'flex-start' }}
        >
          <Card className={classes.paymentCard}>
            <Content className={classes.paymentContent}>
              <Flex
                direction='column'
                className={classes.amountDue}
                alignItems='center'
              >
                <Flex
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Text className={classes.dueText}>Due today</Text>
                  <Text className={classes.amountText} size={23}>
                    $0.00
                  </Text>
                </Flex>
                <Flex>
                  <Divider
                    className={classes.horizontalDashes}
                    variant='no-bottom-margin'
                  />
                </Flex>
                <Flex>
                  <Text size={13}>No upfront fees, pay as you save.</Text>
                </Flex>
              </Flex>
            </Content>
            <Divider variant='middle' />
            <Card noMargin noBorder>
              {showChargebeePaymentMethod ? (
                <ChargeBeePaymentForm />
              ) : (
                <AddPaymentMethod
                  showBankNotice={false}
                  setPaymentMethod={setPaymentMethod}
                />
              )}
              {!paymentMethod && (
                <Flex
                  direction='column'
                  gap={8}
                  className={classes.paymentMessage}
                >
                  <Text size={13} color={'red'}>
                    * You are required to connect a billing method before
                    proceeding. You will not be charged until you saved.
                  </Text>
                </Flex>
              )}
            </Card>
            <Divider variant='middle' />
            <Content>
              <Checkbox
                options={[
                  {
                    checked: termsAccepted,
                    text: (
                      <>
                        By clicking Start Saving, {company.name} is entering
                        into an agreement with MainStreet Work, Inc governed by
                        the{' '}
                        {termsPresent.map((term, index) => (
                          <React.Fragment key={index}>
                            {term}
                            {index < termsPresent.length - 1 ? ', ' : ''}
                          </React.Fragment>
                        ))}{' '}
                        and you confirm you are authorized to do so on behalf of{' '}
                        {company.name}.
                      </>
                    ),
                    value: 'checked',
                  },
                ]}
                fontWeight={'regular'}
                onChange={(e) => {
                  setTermsAccepted(!termsAccepted);
                }}
              />
              <Text size={13}></Text>
              <Flex justifyContent='flex-end'>
                <Button
                  className={classes.startSavingButton}
                  disabled={!(paymentMethod && termsAccepted) || onSubmit}
                  label={onSubmit ? 'Submitting...' : 'Start Saving'}
                  onClick={(): void => {
                    submitForm();
                  }}
                ></Button>
              </Flex>
            </Content>
          </Card>
        </Flex>
        {app.isMobile && showFinePrint()}
      </Flex>
      {!app.isMobile && (
        <>
          <Flex
            className={classes.finePrint}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ margin: '0 auto' }}
          >
            <Divider variant='full-width' className={classes.finePrint} />
            {showFinePrint()}
          </Flex>
        </>
      )}
      <Modal showModal={transtionModalVisible}>
        <Flex
          direction='column'
          alignItems='center'
          justifyContent='center'
          gap={16}
          padding={24}
        >
          <Text size={23}>Get ready to start saving</Text>
          <LoadingWidget />
        </Flex>
      </Modal>
    </div>
  );
});

export default SavingsAgreement;
