import React from 'react';
import { observer } from 'mobx-react';
import { TaxCreditPageBody } from './TaxCreditPageBody';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  claimButtonCta: {
    maxWidth: 150,
  },
}));

type PrepareForNextTaxYearPros = {
  taxYear: number;
};

export const PrepareForNextTaxYear = observer(
  ({ taxYear }: PrepareForNextTaxYearPros) => {
    const NEXT_TAX_YEAR = taxYear;

    const bodyTitle = `Prepare for ${NEXT_TAX_YEAR} Tax Credits`;

    const bodySubtitle = `Get started by connecting your payroll account. When the ${NEXT_TAX_YEAR} tax season begins, we'll contact you with instructions for tax credit calculations.`;

    return (
      <TaxCreditPageBody bodyTitle={bodyTitle} bodySubtitle={bodySubtitle} />
    );
  },
);
