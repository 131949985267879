import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Heading, Image } from 'component-library';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  creditSection: {
    width: 'calc(100% - 48px)',
    maxWidth: '1024px',
    margin: '0 auto',
    padding: 'clamp(40px, 50vw, 80px) 24px',
    textAlign: 'center',
    alignItems: 'center',
  },
  background: {
    backgroundColor: Color.neutral._light_20,
  },
}));

export const CreditsSection: React.FC = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();

  return (
    <div className={classes.background}>
      <Flex
        direction='column'
        className={classes.creditSection}
        gap={app.isTablet ? 24 : 40}
      >
        <Heading color={Color.green._70} size={18}>
          We help you claim the following and more...
        </Heading>
        <Flex direction={app.isMobile ? 'column' : 'row'} gap={24}>
          <Flex
            direction={'row'}
            gap={app.isTablet ? 24 : 40}
            justifyContent={'center'}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/welcome/rd.svg`}
              alt={
                'Icon representing Research and Development for R&D tax credit information'
              }
            />
            <Image
              src={`${process.env.PUBLIC_URL}/images/welcome/disabled-access.svg`}
              alt={
                'Icon of a wheelchair symbolizing Disabled Access Tax Credit details'
              }
            />
          </Flex>
          <Flex
            direction={'row'}
            gap={app.isTablet ? 24 : 40}
            justifyContent={'center'}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/welcome/healthcare.svg`}
              alt={
                'Medical cross icon linking to Healthcare Tax Credit specifics'
              }
            />
            <Image
              src={`${process.env.PUBLIC_URL}/images/welcome/retirement-plan.svg`}
              alt={
                'Retirement savings icon for information on Retirement Plans Tax Credit'
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
});
