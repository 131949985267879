import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Card,
  Color,
  Divider,
  Flex,
  Link,
  Text,
} from 'component-library';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import { ProgramData } from 'lib/interfaces';
import {
  Page,
  ProgramStageEnum,
  ProgramSubStageEnum,
  StatePrograms,
} from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { ClaimBanner } from './ClaimBanner';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  alertInfo: {
    border: `1px solid ${Color.blue._50}`,
    backgroundColor: Color.blue._10,
    borderRadius: '4px',
  },
  instructions: {
    border: `1px solid ${Color.neutral._40}`,
    backgroundColor: Color.neutral._light_20,
    borderRadius: '4px',

    '& > ul': {
      margin: 0,
      paddingLeft: 16,

      '& li': {
        paddingBottom: 16,

        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  },
}));

interface TaxCreditClaimCompleteProps {
  taxYear: number;
  isPayrollType?: boolean;
  programs: ProgramData[];
  completed8974?: boolean;
}

export const TaxCreditClaimComplete: React.FC<TaxCreditClaimCompleteProps> =
  observer(({ taxYear, isPayrollType, programs, completed8974 }) => {
    const classes = useStyles();
    const [payrollContinueLoading, setPayrollContinueLoading] =
      useState<boolean>(false);
    const { form8974 } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const taxYearPrograms = programs?.filter(
      (p) =>
        p.taxYear === taxYear &&
        !StatePrograms.includes(p.name) &&
        p.stage === ProgramStageEnum.FINISHED,
    );
    const featureFlags = useFeatureFlags();
    const showCreditRedemption = featureFlags.showUpdatedCreditsRedemption;

    const handleOnContinuePayrollType = async () => {
      setPayrollContinueLoading(true);

      for (const program of taxYearPrograms) {
        try {
          await form8974.updateProgramSubStage(
            program.id,
            ProgramSubStageEnum.REDEEMING,
          );
        } catch (error) {
          datadogLogs.logger.error(
            `Failed to update program sub-stage for program ID ${program.id} to redeeming:`,
          );
        }
      }

      setPayrollContinueLoading(false);
      if (showCreditRedemption) {
        app.history.push(`/${Page.creditsRedemption}`);
      } else {
        app.history.push(`/${Page.redemption}`);
      }
    };

    return (
      <>
        {isPayrollType ? (
          <ClaimBanner
            title={'One more step!'}
            subtitle={
              <>
                You have one more step to redeem your payroll tax&nbsp;credits.
              </>
            }
            buttonCta={
              <Button
                label='Redeem credits'
                variant='outlined'
                flexAlignSelf='flex-start'
                onClick={() => handleOnContinuePayrollType()}
                loading={payrollContinueLoading}
              />
            }
          />
        ) : (
          <ClaimBanner
            title={'Congratulations!'}
            subtitle={
              <>
                <Text>
                  You have completed all actions to redeem your {taxYear}{' '}
                  tax&nbsp;credits.
                </Text>
                <Text>Return next year to earn more&nbsp;credits!</Text>
              </>
            }
          />
        )}
        <Card noMargin key={`${taxYear}-assessments-credit-claimed`}>
          <Flex direction='column' gap={16} padding={24}>
            <Flex direction='column' gap={4}>
              <Text variant='medium' size={18}>
                Earn more credits
              </Text>
              <Text>
                We&apos;re here to help you maximize your tax savings every
                year.
              </Text>
              <Text>
                Here are a few things you could do now, to get more credits next
                year:
              </Text>
            </Flex>
            <Flex
              className={classes.instructions}
              direction='column'
              padding={24}
              gap={12}
            >
              <Flex direction='column' gap={4}>
                <Text variant='medium'>Verify your payroll connection</Text>
                <Text>
                  Visit the{' '}
                  <Link href={`/${Page.connections}`}>Connections page</Link> to
                  confirm your payroll connection. We review this data to
                  determine your eligibility for future credits.
                </Text>
              </Flex>
              <Divider variant='no-bottom-margin' />
              <Flex direction='column' gap={4}>
                <Text variant='medium'>Invest in disabled access</Text>
                <Text>
                  You could receive up to $5,000 in credit each year for
                  expanding accessibility to persons with disabilities. Consider
                  spending on ergonomic office furniture, web accessibility
                  testing, and text-to-speech capabilities.
                </Text>
              </Flex>
              <Divider variant='no-bottom-margin' />
              <Flex direction='column' gap={4}>
                <Text variant='medium'>Offer a qualifying Retirement Plan</Text>
                <Text>
                  You could start a 401(k) for your employees, and get up to
                  100% of the costs back as a tax credit.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <ActivityTimelineSideDrawer
          taxYear={taxYear.toString()}
          stepIndex={6}
        />
      </>
    );
  });
